import { Box, Text } from '@chakra-ui/layout';
import { Flex, Spinner, Switch, useColorModeValue } from '@chakra-ui/react';
import React, { useRef, useEffect } from 'react';
import { useLoadingContext } from '../context/LoadingContext';
import { useUserContext } from '../context/UserContext';
import { useLoading } from '../utils/hooks';

function Controls({ on, onSwitchClick, status }) {
  const boxBg = useColorModeValue('white', '#222a38');
  const globalLoading = useLoadingContext();
  const user = useUserContext();
  const interval = useRef(false);
  const loader = useLoading();

  useEffect(() => {
    interval.current = setInterval(async () => {
      if (user) {
        loader.start();
        await user.updateUser();
        loader.stop();
      }
    }, 5000);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = false;
      }
    };
  }, []);
  return (
    <Box
      bg={boxBg}
      pl={3}
      pr={3}
      pt={4}
      pb={4}
      borderRadius="12px"
      width="100%"
    >
      <Flex alignItems="center">
        <Text color="gray.500" fontWeight="semibold" fontSize="xl">
          Controls
        </Text>
        {loader.isLoading() && <Spinner size="sm" color="gray.600" ml={4} />}
      </Flex>
      <Flex alignItems="center" mt={2} justifyContent="space-between">
        <Text fontWeight="bold" fontSize="lg">
          System is {status && status.toLowerCase()}...
        </Text>
        <Switch
          isDisabled={
            status === 'Starting' ||
            status === 'Stopping' ||
            globalLoading.isLoading()
          }
          isChecked={on}
          colorScheme="green"
          onChange={onSwitchClick}
        />
      </Flex>
    </Box>
  );
}

export default Controls;
