import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Landing from '../pages/Landing/Landing';
import NavigationBar from './NavigationBar';
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import { useUserContext } from '../context/UserContext';
import Dashboard from '../pages/Dashboard/Dashboard';
import Home from '../pages/Home';
import Profile from '../pages/Profile';
import SetUp from './SetUp';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/Terms';

function PrivateRoute({
  component: Component,
  redirectPath,
  auth,
  isSetUp,
  ...rest
}) {
  const user = useUserContext();

  function getRenderComponent(props) {
    if (auth) {
      if (user) {
        if (
          (!user.sparkOptions || !user.sparkOptions.proxyInstalled) &&
          !user.sparkOptions &&
          !window.location.pathname.includes('set-up') &&
          !window.location.pathname.includes('profile')
        ) {
          return (
            <Redirect
              to={{
                pathname: '/set-up',
                state: { from: props.location },
              }}
            />
          );
        }

        if (
          isSetUp &&
          user.sparkOptions &&
          user.sparkOptions.proxyInstalled &&
          window.location.pathname.includes('set-up')
        ) {
          return (
            <Redirect
              to={{ pathname: '/home', state: { from: props.location } }}
            />
          );
        }
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: props.location },
          }}
        />
      );
    }

    if (!user) return <Component {...props} />;

    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { from: props.location },
        }}
      />
    );
  }

  return <Route {...rest} render={getRenderComponent} />;
}

function AppRouter() {
  return (
    <BrowserRouter>
      <Route path="/" component={NavigationBar} />
      <PrivateRoute
        path="/login"
        component={Login}
        exact
        redirectPath="/home"
      />
      <PrivateRoute
        path="/register/invite/:inviteId"
        component={Register}
        exact
        redirectPath="/home"
      />
      <PrivateRoute
        path="/register/referral/:referralCode"
        component={Register}
        exact
        redirectPath="/home"
      />
      <PrivateRoute
        path="/register"
        component={Register}
        exact
        redirectPath="/home"
      />
      <PrivateRoute path="/" component={Landing} exact redirectPath="/home" />
      <PrivateRoute
        exact
        path="/home"
        auth
        component={Home}
        redirectPath="/login"
      />
      <PrivateRoute
        exact
        path="/set-up"
        auth
        isSetUp
        component={SetUp}
        redirectPath="/login"
      />
      <PrivateRoute
        exact
        path="/profile"
        auth
        component={Profile}
        redirectPath="/login"
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms" component={TermsOfService} />
    </BrowserRouter>
  );
}

export default AppRouter;
