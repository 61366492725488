import { Box, Flex, Stack, Text } from '@chakra-ui/layout';
import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { useLocationContext } from '../context/LocationContext';
import { Button } from '@chakra-ui/react';
import BlockDetailsModal from './BlockDetailsModal';
import { useToggle } from '../utils/hooks';

function BlockItem({ block, colorMode, type, inDialog, showReason }) {
  const { locationMap } = useLocationContext();
  const showDetails = useToggle(false);

  return (
    <React.Fragment>
      <Box
        background={
          colorMode === 'dark'
            ? inDialog
              ? 'rgb(27 34 46)'
              : 'rgb(38,48,64)'
            : '#f3f7fb'
        }
        p={4}
        borderRadius={8}
      >
        <Flex flexDir="row" alignItems="center">
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontWeight="semibold" fontSize="3xl">
              ${block.price}
            </Text>
            <Button
              onClick={() => showDetails.show()}
              size="xs"
              justifySelf="flex-end"
              alignSelf="flex-end"
            >
              Details
            </Button>
          </Flex>
          <Stack ml={4}>
            <Text fontWeight="semibold" fontSize="md">
              {block.location}
            </Text>
            <Text fontSize="sm" color="gray.500">
              Starts: {moment(block.startTime).format('MM/DD h:mm A')}
            </Text>
            {showReason && (
              <Text fontSize="sm" color="gray.500">
                Reject Reason:{' '}
                {block.reason &&
                  block.reason
                    .replace('TimeBuffer', 'Time until start')
                    .replace('Area', 'Location not selected')}
              </Text>
            )}
          </Stack>
        </Flex>
      </Box>
      <BlockDetailsModal
        isOpen={showDetails.isActive()}
        onClose={showDetails.hide}
        block={block}
        type={type}
      />
    </React.Fragment>
  );
}

export default BlockItem;
