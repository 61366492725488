import { Text } from '@chakra-ui/layout';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  Select,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocationContext } from '../context/LocationContext';
import { useLoadingContext } from '../context/LoadingContext';
import { useUserContext } from '../context/UserContext';
import { patchUser } from '../services/UserService';
import { useTour } from 'react-rtg';
import FilterTour from '../tours/FilterTour';
import { useLoading } from '../utils/hooks';
import { useNotifyContext } from '../context/NotifyContext';
import { useTabContext } from '../context/TabContext';
import moment from 'moment-timezone';

const MAX_TRIPS = Array(20)
  .fill(0)
  .map((item, idx) => {
    return { label: idx + 1, value: idx + 1 };
  });

const MINIMUM_PRICE = Array(7)
  .fill(0)
  .map((item, idx) => {
    const price = item + idx * 5;
    return {
      label: price ? '$' + price : 'Any',
      value: price ? price : price,
    };
  });

const TIME_BUFFERS = Array(4)
  .fill(0)
  .map((item, idx) => {
    const price = item + idx * 10;
    return {
      label: price ? price + ' min' : 'Any',
      value: price ? price - 1 : price,
    };
  });

function FilterItemInput({ options, label, userOptions, name, onInputChange }) {
  const user = useUserContext();
  const globalLoading = useLoadingContext();

  return (
    <Flex
      id={name}
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text fontWeight="semibold">{label}</Text>
      <Select
        value={user?.[userOptions]?.[name]}
        onChange={onInputChange}
        name={name}
        w="125px"
        disabled={globalLoading.isLoading()}
        variant="outline"
      >
        {options.map(item => (
          <option value={item.value}>{item.label}</option>
        ))}
      </Select>
    </Flex>
  );
}

function LocationFilter({ userOptions, name, onLocationChange }) {
  const user = useUserContext();
  const { locations } = useLocationContext();

  return (
    <Box id="locations">
      <Text mb={4} fontWeight="semibold">
        Locations
      </Text>
      {locations &&
        locations.map(location => (
          <React.Fragment>
            <Flex
              pl={1}
              pt={2}
              pb={2}
              pr={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text pr={2} textAlign="left">
                {location.name}
              </Text>
              <Checkbox
                isChecked={user?.[userOptions]?.[name]?.includes(
                  location.storeId,
                )}
                size="lg"
                onChange={() => onLocationChange(location.storeId)}
              />
            </Flex>
            <Divider />
          </React.Fragment>
        ))}
    </Box>
  );
}

function AdvancedFilters({ shouldBeInFilters, userOptions, locationsName }) {
  const user = useUserContext();
  const globalLoading = useLoadingContext();
  const loader = useLoading();
  const notify = useNotifyContext();
  const locations = useLoadingContext();

  const { setCurrentTab } = useTabContext();

  const [tour] = useTour('filterTour');

  useEffect(() => {
    if (shouldBeInFilters) {
      tour.open();
    }
  }, []);

  async function onLocationChange(id) {
    globalLoading.start();
    const copy = JSON.parse(JSON.stringify(user[userOptions]));

    let val = copy[locationsName];

    if (!val) {
      val = [];
    }

    const idxOf = val.indexOf(id);

    if (idxOf > -1) {
      val.splice(idxOf, 1);
    } else {
      val.push(id);
    }

    copy[locationsName] = val;

    try {
      const response = await patchUser({
        userId: user.userId,
        body: { [userOptions]: copy },
      });

      await user.updateUser();
      globalLoading.stop();
      notify.success('Updated locations');
    } catch (e) {
      globalLoading.stop();
      notify.error('Error changing location');
    }
  }

  async function onDaysClick(day) {
    globalLoading.start();
    let days =
      user[userOptions] && user[userOptions].days ? user[userOptions].days : '';

    const splitDays = days ? days.split(',') : [];
    const idx = splitDays.indexOf(String(day));

    if (idx > -1) {
      splitDays.splice(idx, 1);
    } else {
      splitDays.push(day);
    }

    const joined = splitDays.join(',');

    const copy = JSON.parse(JSON.stringify(user[userOptions]));

    copy['days'] = joined;

    try {
      const response = await patchUser({
        userId: user.userId,
        body: { [userOptions]: copy },
      });

      await user.updateUser();
      globalLoading.stop();

      notify.success('Successfully updated ' + ' days');
    } catch (e) {
      globalLoading.stop();
      notify.error('Error updating ' + 'days' + ' ' + e.message);
    }
  }

  async function onInputChange(e) {
    globalLoading.start();
    const { name, value } = e.target;

    const realVal = name === 'deliveryType' ? value : Number(value);

    const copy = JSON.parse(JSON.stringify(user[userOptions]));

    // only time buffer is expected to be an int.
    // startHour and hours are exptected to be strings in backend
    if (name === 'timeBuffer' || name === 'maxTrips' || name === 'minPrice') {
      copy[name] = realVal;
    } else {
      copy[name] = String(realVal);
    }

    try {
      const response = await patchUser({
        userId: user.userId,
        body: { [userOptions]: copy },
      });

      await user.updateUser();
      globalLoading.stop();

      if (shouldBeInFilters) {
        tour.next();
      }

      notify.success('Successfully updated ' + name);
    } catch (e) {
      globalLoading.stop();
      notify.error('Error updating ' + name + ' ' + e.message);
    }
  }

  async function onTourFinish() {
    try {
      loader.start();
      const options = {
        ...user[userOptions],
        setupComplete: true,
      };
      await patchUser({
        userId: user.userId,
        body: {
          [userOptions]: options,
        },
      });

      await user.updateUser();
      setCurrentTab(0);
      tour.close();
      loader.stop();
    } catch (e) {
      notify.error('Error completing tour');
      loader.stop();
    }
  }

  function onWaitTour() {
    console.log('CALLED');
    if (!locations || !locations.length) return true;
    return false;
  }

  return (
    <React.Fragment>
      <FilterTour
        onWait={onWaitTour}
        locations={user[userOptions][locationsName] || []}
        loader={loader}
        onTourFinish={onTourFinish}
        onDonePickingDays={() => tour.next()}
        days={
          user[userOptions] && user[userOptions].days
            ? user[userOptions].days
            : ''
        }
      />
      <Stack mt={2}>
        <FilterItemInput
          label="Minimum Price"
          userOptions={userOptions}
          name="minPrice"
          onInputChange={onInputChange}
          options={MINIMUM_PRICE}
        />
        <Divider />
        <FilterItemInput
          label="Max # of trips"
          userOptions={userOptions}
          name="maxTrips"
          onInputChange={onInputChange}
          options={MAX_TRIPS}
        />
        <Divider />
        <FilterItemInput
          label="Time until shift starts"
          userOptions={userOptions}
          name="timeBuffer"
          onInputChange={onInputChange}
          options={TIME_BUFFERS}
        />
        <Divider />
        <FilterItemInput
          label="Delivery Type"
          userOptions={userOptions}
          name="deliveryType"
          onInputChange={onInputChange}
          options={[
            { label: 'All', value: 'ALL' },
            { label: 'Delivery', value: 'DELIVERY' },
            { label: 'Shopping', value: 'SHOPPING' },
          ]}
        />
        <Divider />
        <LocationFilter
          onLocationChange={onLocationChange}
          userOptions={userOptions}
          name={locationsName}
        />
      </Stack>
    </React.Fragment>
  );
}

export default AdvancedFilters;
