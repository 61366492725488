import HttpService from './HttpService';

export async function getLocations() {
  try {
    const response = await HttpService.get('/spark/stores');
    return response.data;
  } catch (e) {
    throw e;
  }
}
