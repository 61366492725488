import { Tour, Step } from 'react-rtg';
import { Text, Button, useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import './Tour.css';

const FilterTour = ({
  onWait,
  loader,
  locations,
  onTourFinish,
  days,
  onDonePickingDays,
}) => {
  const [isOpen, setOpen] = useState(false);

  const { colorMode } = useColorMode();

  useEffect(() => {
    const modal = document.querySelector('.rtg__modal');
    if (modal) {
      modal.style.background = colorMode === 'light' ? 'white' : '#101218';
    }
  }, [colorMode]);

  return (
    <Tour
      isOpen={isOpen}
      id="filterTour"
      closeButton={false}
      controlNumbers={false}
      controls={() => {}}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      badgeClassName="bargain-rtg-badge"
      className="bargain-tour"
    >
      <Step className="bargain-tour" selector="#minPrice" placement="bottom">
        <Text>
          Select the least amount of money you're willing to accept per block.
        </Text>
      </Step>
      <Step selector="#maxTrips" placement="bottom">
        <Text>Select the maximum amount of trips you would do per block.</Text>
      </Step>
      <Step selector="#timeBuffer" placement="bottom">
        <Text>
          This is the amount of time that you have from the current time and the
          time that the shift starts. For Example, if the current time is 9:30
          am. If you would like to only accept shifts that start from 10am
          onwards your time until start would be 30 min.
        </Text>
      </Step>
      <Step selector="#deliveryType" placement="bottom">
        <Text>
          Here you can select what type of blocks you want to do. You can accept
          any , only delivery, or only shopping ones.
        </Text>
      </Step>
      <Step
        onWait={onWait}
        selector="#locations"
        placement="top"
        pinOffset={20}
      >
        <Text>
          Pick the stores you would like to work at. (You must select at least
          1.)
        </Text>
        <Button
          onClick={onTourFinish}
          isLoading={loader.isLoading()}
          disabled={loader.isLoading() || !locations.length}
          colorScheme="blue"
          mt={4}
        >
          Done Picking
        </Button>
      </Step>
    </Tour>
  );
};

export default FilterTour;
