import {
  Box,
  Button,
  Flex,
  Center,
  Stack,
  Text,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import Driver from '../../assets/driver.svg';

function Feature({ featureTitle, description }) {
  return (
    <Stack>
      <Text fontWeight="semibold" fontSize="xl">
        {featureTitle}
      </Text>
      <Text>{description}</Text>
    </Stack>
  );
}

function Landing() {
  const history = useHistory();

  function onGetStartedClick() {
    history.push('/register');
  }

  const boxBg = useColorModeValue('white', 'rgb(27 34 46)');

  return (
    <Flex
      flexDir="column"
      margin="0 auto"
      maxW="800"
      spacing={8}
      p={4}
      pt={'64px'}
    >
      <Box
        position="relative"
        display="flex"
        flexDir="column"
        alignItems="center"
      >
        <Text
          p={2}
          position="absolute"
          textAlign="center"
          top="2%"
          fontWeight="semibold"
          maxW="600px"
          textAlign="center"
          alignSelf="center"
          fontSize="5xl"
        >
          Efficient and cheap block grabber
        </Text>
        <Image mt={8} height={400} src={Driver} />
      </Box>
      <Button
        alignSelf="center"
        onClick={onGetStartedClick}
        colorScheme="blue"
        mt={8}
        width="200px"
        size="lg"
      >
        Get Started
      </Button>
      <Stack mt={20} mb={20} bg={boxBg} width="100%" p={8} borderRadius="8px">
        <Text fontWeight="bold" fontSize="2xl" textAlign="center">
          Features
        </Text>
        <Stack spacing={8}>
          <Feature
            featureTitle="Always On"
            description="Full control over when the system is on or off. It can run while you sleep!"
          />
          <Feature
            featureTitle="Fully Customizeable"
            description="You can add many filters for location, working schedule, and time until shift starts."
          />
          <Feature
            featureTitle="Customer Service"
            description="7 days a week customer service. There will be someone ready to help you!"
          />
          <Feature
            featureTitle="Always Improving"
            description="We're always working on adding new features to make this the best experience possible"
          />
        </Stack>
      </Stack>
      <Stack alignSelf="center" direction="row" spacing={2} mt={8}>
        <Button onClick={() => history.push('/terms')} variant="link">
          Terms of service
        </Button>
        <Text>|</Text>
        <Button onClick={() => history.push('/privacy-policy')} variant="link">
          Privacy Policy
        </Button>
      </Stack>
      <Text textAlign="center" mt={2}>
        © 2021 Bargain GoPuff. All rights reserved.
      </Text>
    </Flex>
  );
}

export default Landing;
