import React, { useState } from 'react';
import AdvancedFilters from '../components/AdvancedFilters';
import BasicFilters from '../components/BasicFilters';

const FILTER_TYPES = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
};

const LOCATION_NAME = 'locations';
const USER_OPTIONS = 'sparkOptions';

function Filters({ shouldBeInFilters }) {
  const [filterType, setFilterType] = useState(FILTER_TYPES.BASIC);

  return (
    <React.Fragment>
      <AdvancedFilters
        locationsName={LOCATION_NAME}
        userOptions={USER_OPTIONS}
        shouldBeInFilters={shouldBeInFilters}
        switchToBasic={() => setFilterType(FILTER_TYPES.BASIC)}
      />
    </React.Fragment>
  );
}

export default Filters;
