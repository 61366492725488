import React, { useEffect } from 'react';
import {
  Icon,
  Text,
  Box,
  Button,
  ButtonGroup,
  useColorMode,
  IconButton,
} from '@chakra-ui/react';

import { CgProfile } from 'react-icons/cg';
import { BsFillMoonStarsFill, BsFillSunFill } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';

function NavigationBar() {
  const history = useHistory();
  const { colorMode, toggleColorMode, setColorMode } = useColorMode();
  const user = useUserContext();

  useEffect(() => {
    if (!user) {
      setColorMode('light');
    }
  }, [user]);

  return (
    <Box
      justifyContent="space-between"
      display="flex"
      position="fixed"
      width="full"
      flexDir="row"
      zIndex={999}
      background={colorMode === 'light' ? '#F4F5F7' : '#141922'}
      alignItems="center"
      p={3}
    >
      <Text
        onClick={() => history.push('/')}
        cursor="pointer"
        _hover={{ color: 'blue.600' }}
        colorScheme="blue"
        fontWeight="semibold"
        fontSize="2xl"
      >
        Bargain Spark
      </Text>

      <ButtonGroup size="sm" variant="outline" spacing={4}>
        {!user && (
          <React.Fragment>
            <Button
              onClick={() => history.push('/register')}
              colorScheme="blue"
            >
              register
            </Button>
            <Button
              onClick={() => history.push('/login')}
              variant="solid"
              colorScheme="blue"
            >
              login
            </Button>
          </React.Fragment>
        )}

        {user && (
          <React.Fragment>
            <IconButton
              onClick={toggleColorMode}
              icon={
                colorMode !== 'light' ? (
                  <BsFillSunFill />
                ) : (
                  <BsFillMoonStarsFill />
                )
              }
            />
            <IconButton
              onClick={() => history.push('/profile')}
              icon={<CgProfile />}
            />
          </React.Fragment>
        )}
      </ButtonGroup>
    </Box>
  );
}

export default NavigationBar;
