import HttpService from './HttpService';

const apiUrl = `/spark-setup`;

export async function postCredentials(email, password) {
  const payload = { email, password };

  try {
    const resp = await HttpService.post(apiUrl, payload);
    return resp.data;
  } catch (e) {
    console.log('error adding status: ', e);
    throw e;
  }
}
