import { Text } from '@chakra-ui/layout';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  useToast,
  Stack,
} from '@chakra-ui/react';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotifyContext } from '../context/NotifyContext';
import { useUserContext } from '../context/UserContext';
import { postCredentials } from '../services/SetUpService';
import { patchUser } from '../services/UserService';
import { validateEmail } from '../utils/generic';
import { useFormInput, useLoading } from '../utils/hooks';
import PageWrapper from './PageWrapper';

function SetUp() {
  const email = useFormInput();
  const password = useFormInput();
  const user = useUserContext();
  const notify = useNotifyContext();
  const history = useHistory();

  const toast = useToast();
  const loader = useLoading(false);

  async function handleVerify() {
    try {
      loader.start();

      const emailIsValid = validateEmail(email.value);

      if (!emailIsValid) {
        notify.error('Enter a valid email address.');
        loader.stop();
        return;
      }

      const response = await postCredentials(email.value, password.value);

      if (!response.deliveryArea) {
        loader.stop();
        toast({
          status: 'error',
          title: 'Invalid Credentials',
        });

        return;
      }

      let options = {};

      if (user && user.sparkOptions) {
        options = {
          ...user.sparkOptions,
        };
      }

      options = {
        ...options,
        ...response,
        proxyInstalled: true,
        email: email.value,
        password: password.value,
      };

      if (!options.hasOwnProperty('setupComplete')) {
        options.setupComplete = false;
      }

      if (!options.hasOwnProperty('start')) {
        options.start = true;
        options.status = 'Running';
      }

      const services = user.services.slice(0);

      if (!services.find(service => service.key === 'SPARK')) {
        services.push({
          key: 'SPARK',
          label: 'Spark',
          selected: true,
          price: 50,
        });
      }

      await patchUser({
        userId: user.userId,
        body: { sparkOptions: options, services },
      });

      window.location.reload();
    } catch (e) {
      loader.stop();
      notify.error(e.response.data);
      console.log('ERROR getting response: ', e);
    }
  }

  return (
    <PageWrapper>
      <Text fontSize="2xl" fontWeight="semibold" mt={4}>
        Configure your account
      </Text>

      <Stack spacing={6} mt={4}>
        <FormControl isRequired id="email">
          <FormLabel>Spark Email Address</FormLabel>
          <InputGroup>
            <Input
              disabled={loader.isLoading()}
              {...email}
              placeholder="email address"
              type="email"
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired id="password">
          <FormLabel>Spark Password</FormLabel>
          <InputGroup>
            <Input
              disabled={loader.isLoading()}
              {...password}
              placeholder="password"
              type="password"
            />
          </InputGroup>
        </FormControl>
        <Button
          disabled={loader.isLoading() || !email.value || !password.value}
          onClick={handleVerify}
          isLoading={loader.isLoading()}
          colorScheme="blue"
        >
          {'Verify'}
        </Button>
      </Stack>
    </PageWrapper>
  );
}

export default SetUp;
