import { Box, Stack } from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import Controls from '../../components/Controls';
import PageWrapper from '../../components/PageWrapper';
import StatsCard from '../../components/StatsCard';
import { useLoadingContext } from '../../context/LoadingContext';
import { useNotifyContext } from '../../context/NotifyContext';
import { useUserContext } from '../../context/UserContext';
import { getDashboardData } from '../../services/DashboardService';
import { patchUser } from '../../services/UserService';

function Dashboard() {
  const notify = useNotifyContext();
  const user = useUserContext();
  const globalLoading = useLoadingContext();
  const [hours, setHours] = useState({ got: 0, dislikes: 0 });
  const [blocks, setBlocks] = useState({ got: [], dislikes: [] });

  async function loadData() {
    try {
      const gigsResponse = await getDashboardData();
      console.log('gigsResponse: ', gigsResponse);
      setHours(gigsResponse.blockMoney);
      setBlocks(gigsResponse.blocks);
    } catch (e) {
      notify.error('Error getting gigs: ', +e.message);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  async function onSwitchClick() {
    globalLoading.start();
    const newValue = !user.goPuffOptions.start;

    const newStatus = newValue ? 'Running' : 'Stopped';

    const options = {
      ...user.goPuffOptions,
      start: newValue,
      status: newStatus,
    };

    try {
      await patchUser({
        userId: user.userId,
        body: { goPuffOptions: options },
      });
      await user.updateUser();

      globalLoading.stop();
    } catch (e) {
      console.log('E: ', e);
      notify.error('Error switching system');
      globalLoading.stop();
    }
  }

  return (
    <Box>
      <Stack spacing={4}>
        <Controls
          status={user.goPuffOptions ? user.goPuffOptions.status : 'Stopped'}
          onSwitchClick={onSwitchClick}
          on={user.goPuffOptions ? user.goPuffOptions.start : false}
        />
        <StatsCard money={hours.got} blocks={blocks.got} type="Won" />
        <StatsCard
          money={hours.dislikes}
          blocks={blocks.dislikes}
          type="Rejected"
        />
      </Stack>
    </Box>
  );
}

export default Dashboard;
