import { ChakraProvider, Text } from '@chakra-ui/react';
import React from 'react';
import './App.css';
import AppRouter from './components/AppRouter';
import LoadingContextProvider from './context/LoadingContext';
import LocationContextProvider from './context/LocationContext';
import NotifyContextProvider from './context/NotifyContext';
import UserContextProvider from './context/UserContext';
import theme from './utils/theme';
import { TourRoot } from 'react-rtg';
import FilterTour from './tours/FilterTour';


function App() {
  return (
    <React.Fragment>
      <ChakraProvider theme={theme}>
        <TourRoot>
          <NotifyContextProvider>
            <LoadingContextProvider>
              <UserContextProvider>
                <LocationContextProvider>
                  <AppRouter />
                </LocationContextProvider>
              </UserContextProvider>
            </LoadingContextProvider>
          </NotifyContextProvider>
        </TourRoot>
      </ChakraProvider>
    </React.Fragment>
  );
}

export default App;
