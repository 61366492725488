import { Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import React from 'react';
import moment from 'moment-timezone';
import { Box, Flex, Stack, useColorMode } from '@chakra-ui/react';

function Stat({ label, value }) {
  const { colorMode } = useColorMode();

  return (
    <Flex
      pt={2}
      pb={2}
      pl={4}
      pr={4}
      borderRadius="15px"
      background={colorMode === 'dark' ? 'rgb(38,48,64)' : '#f3f7fb'}
      flexDir="column"
      alignItems="flex-start"
    >
      <Text fontSize="lg" fontWeight="semibold">
        {label}:
      </Text>
      <Text fontSize="lg">{value}</Text>
    </Flex>
  );
}

function BlockDetailsModal({ isOpen, onClose, block, type }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Shift Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={2}>
            <Stat label="Status" value={type} />
            <Stat label="Price" value={'$' + block.price} />
            <Stat
              label="Starts At"
              value={moment(block.startTime).format('MM/DD h:mm A')}
            />
            <Stat label="Location" value={block.location} />
            <Stat
              label="Accepted"
              value={moment(block.accepted).format('MM/DD h:mm A')}
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default BlockDetailsModal;
