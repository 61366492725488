import { Circle, Grid, Stack, Text, Wrap } from '@chakra-ui/layout';
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  IconButton,
  useColorMode,
} from '@chakra-ui/react';
import { Slide } from '@chakra-ui/transition';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import BlockItem from './BlockItem';

function AllBlocks({ blocks, showBlocks, type }) {
  const { colorMode } = useColorMode();

  return (
    <Slide unmountOnExit in={showBlocks.isActive()} style={{ zIndex: 1399 }}>
      <Stack
        p={4}
        height="full"
        background={colorMode === 'light' ? 'white' : '#141922'}
      >
        <Flex dir="row" alignItems="center" justifyContent="space-between">
          <Flex direction="row" alignItems="center">
            <Text fontSize="2xl" fontWeight="semibold">
              All {type} Shifts
            </Text>
          </Flex>
          <CloseButton size="lg" onClick={showBlocks.hide} />
        </Flex>
        <Stack overflow="scroll" spacing={4}>
          {blocks &&
            !!blocks.length &&
            blocks.map(block => (
              <BlockItem
                inDialog
                type={type}
                block={block}
                colorMode={colorMode}
                showReason={type === 'Rejected'}
              />
            ))}
        </Stack>
      </Stack>
    </Slide>
  );
}

export default AllBlocks;
