import axios from 'axios';
import { cleanPhoneNumber } from '../utils/generic';
import { API_ENDPOINT } from './HttpService';

const ACCESS_TOKEN_KEY = 'auth_bargain';
const REFRESH_TOKEN_KEY = 'auth_bargain_refresh';
const GHOST_APP = 'BargainSpark';

export function logOut() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function checkSession() {
  const token = getAuthToken();

  return token;
}

export function checkIfTokenIsExpired(token) {
  const splitToken = token.split('.');
  const payloadEncoded = splitToken[1];
  const decodedPayload = atob(payloadEncoded);
  const payload = JSON.parse(decodedPayload);

  const { exp } = payload;

  if (Date.now() / 1000 > exp) {
    return true;
  }
  return false;
}

export function getAuthToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export async function requestForgotPasswordCode(phoneNumber) {
  const cleanPhone = cleanPhoneNumber(phoneNumber);

  try {
    const codeRequested = await axios.get(
      API_ENDPOINT +
        '/forgot-password?phoneNumber=' +
        cleanPhone +
        '&ghostApp=' +
        GHOST_APP,
    );

    return codeRequested.data;
  } catch (e) {
    throw e;
  }
}

export async function submitForgotPasswordRequest(phoneNumber, code, password) {
  const cleanPhone = cleanPhoneNumber(phoneNumber);

  try {
    const response = await axios.post(API_ENDPOINT + '/forgot-password', {
      phoneNumber: cleanPhone,
      password,
      verificationCode: code,
      ghostApp: GHOST_APP,
    });

    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function login({ phoneNumber, password }) {
  try {
    const response = await axios.post(API_ENDPOINT + '/login/bargain', {
      phoneNumber,
      password,
      ghostApp: GHOST_APP,
    });

    const { data } = response;
    if (data) {
      localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
      return;
    } else {
      throw new Error('You were not able to log in. Your user does not exist.');
    }
  } catch (e) {
    throw e;
  }
}

export async function refreshToken() {
  const phoneNumber = '';
  try {
    const response = await axios.post(API_ENDPOINT + '/login/bargain', {
      phoneNumber,
      ghostApp: GHOST_APP,
      refreshToken: getRefreshToken(),
    });

    const { data } = response;
    if (data) {
      localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
      console.log('hello: ', data);
      return;
    } else {
      throw new Error('You were not able to log in. Your user does not exist.');
    }
  } catch (e) {
    console.log('ERRO: ', e);
    throw e;
  }
}

export async function register(
  phoneNumber,
  password,
  name,
  inviteId,
  referralCode,
) {
  try {
    let body = {
      phoneNumber,
      password,
      name,
      ghostApp: GHOST_APP,
      referralCode: referralCode,
    };

    if (inviteId) {
      body.inviteId = inviteId;
    }

    const response = await axios.post(API_ENDPOINT + '/user', body);

    return response.data;
  } catch (e) {
    throw e;
  }
}
